<div class="left-section">
  <a
    *ngIf="showBackButton"
    class="back-button"
    (click)="navigateToPreviosPage()"
  >
    <lss-icon>keyboard_arrow_left</lss-icon>
    Back
  </a>

  <lss-button
    class="cv-button"
    [matMenuTriggerFor]="menu"
    *ngIf="
      (authService.userCanPerformAction$(canAccessActions) | async) &&
      showActionsButton()
    "
    (click)="handleMenuClick()"
  >
    Actions
    <lss-icon> arrow_drop_down </lss-icon>
  </lss-button>
  <mat-menu #menu="matMenu">
    <button
      id="generateCv"
      class="drop-down"
      (click)="getCVDocxTemplated()"
      mat-button
      *ngIf="canGenerateCv$ | async"
    >
      <mat-icon class="material-icons-outlined">description</mat-icon>
      Generate CV in Docx
    </button>
    <button
      id="generatePDF"
      class="drop-down"
      *ngIf="canGenerateCv$ | async"
      mat-button
      (click)="getCVPdf()"
    >
      <mat-icon class="material-icons-outlined">picture_as_pdf</mat-icon>
      Generate CV in PDF
    </button>
    <button
      *ngIf="canImportFromLinkedin$ | async"
      id="importLinkedIn"
      class="drop-down"
      (click)="openLinkedInModal()"
      mat-button
      [style.display]="authService.enableLinkedInImport.value ? null : 'none'"
    >
      <mat-icon>upload_file</mat-icon>
      Import CV from LinkedIn
    </button>
    <button
      *ngIf="(canCopyUrl$ | async) && (isCandidateCv$ | async)"
      mat-button
      class="drop-down"
      (click)="copyCandidateCvLink()"
    >
      <mat-icon class="material-icons-outlined">file_copy</mat-icon>
      Copy Link to Profile
    </button>
    <button
      *ngIf="authService.userCanPerformAction$(canRegisterCandidate) | async"
      mat-button
      class="drop-down"
      (click)="goToRegisterCandidate()"
    >
      <mat-icon class="material-icons-outlined">portrait</mat-icon>
      Register a New Candidate
    </button>
    <button
      *ngIf="authService.userCanPerformAction$(CandRegisterExternalHr) | async"
      mat-button
      class="drop-down"
      (click)="goToRegisterExternalHr()"
    >
      <mat-icon class="material-icons-outlined">assignment_ind</mat-icon>
      Register an External HR
    </button>
    <button
      *ngIf="(canConvertExEmployee$ | async) && (isExEmployeeCv$ | async)"
      mat-button
      class="drop-down"
      (click)="goToRegisterCandidate(true)"
    >
      <mat-icon class="material-icons-outlined">change_circle</mat-icon>
      Convert to Candidate
    </button>
  </mat-menu>
</div>

<div class="right-section">
  <lss-searchbox
    *ngIf="
      isSearchVisible && authService.userCanPerformAction$(canSearch) | async
    "
  >
  </lss-searchbox>
  <button
    *ngIf="authService.authenticatedUser$ | async"
    mat-button
    [matMenuTriggerFor]="user"
    (click)="handleMenuClick()"
  >
    <div *ngIf="isPictureLoaded$ | async; then picture; else icon"></div>
    <ng-template #picture
      ><img [src]="loggedUserProfilePicture$ | async" class="profile-picture"
    /></ng-template>
    <ng-template #icon><lss-icon>account_circle</lss-icon></ng-template>

    <span>{{ (userFullName$ | async) ?? fullName }}</span>
    <lss-icon>arrow_drop_down</lss-icon>
  </button>
  <mat-menu #user="matMenu">
    <button *ngIf="showReportButton()" mat-menu-item (click)="reportIssue()">
      Report issues
    </button>
    <button mat-menu-item (click)="logout()">Log out</button>
  </mat-menu>
</div>
