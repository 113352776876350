import { Inject, Injectable } from '@angular/core';
import {
  DATA_SERVICE_TOKEN,
  DocxParagraphObj,
  DocxRoleSection,
  ICvCertificationItem,
  ICvWorkExperienceItem,
  IEducationDegree,
  IEducationItem,
  IEmployeeCv,
  ILanguageProficiency,
  ILookupData,
  IOrderedLearnedLanguage,
  IPeriodItem,
  ITechnicalSkill,
} from '@lss/lss-types';
import { getDataFromSessionStorage } from '@lss/lss-ui';
import { DataService } from '../data.service';
import { AlignmentType, Header, Packer, Paragraph, Table, TextRun, UnderlineType } from 'docx';
import * as saveAs from 'file-saver';
import { CapitalizeFirstLetter, formatTimestamp } from '../../utils';
import {
  BlackLineTop,
  BuildDocx,
  BuildTableCompanyPeriod,
  BulletedNumberedParagraph,
  BulletedParagraph,
  CompanyNameParagraph,
  DegreeText,
  EducationParagraph,
  EmptyParagraph,
  EmptyTextNewLine,
  GreyLineTop,
  LanguageParagraph,
  LeftParagraphPadding,
  LogoImagePath,
  NewParagraph,
  NewText,
  NumLinesOrangeColumn,
  OrangeColumnSectionTitleParagraph,
  PeriodParagraph,
  RightParagraphPadding,
  RoleParagraph,
  SummaryTitleParagraph,
  WorkExperienceTitleParagraph,
} from './docx-definitions';

import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import angularParser from 'docxtemplater/expressions.js';



@Injectable({
  providedIn: 'root',
})
export class CvFileBuilderService {
  fullName: string;
  logoImagePath = LogoImagePath;
  logoImageBlob: Blob;
  numEducationLines = 0;
  numSkillsToDisplay = 0;

  constructor(
    @Inject(DATA_SERVICE_TOKEN)
    private dataService: DataService,
  ) { }

  async urlToBlob(url: string): Promise<Blob> {
    return (await fetch(url)).blob();
  }

  async getCVDocx(): Promise<void> {
    const logo = await this.urlToBlob(this.logoImagePath);
    const cv = getDataFromSessionStorage('cv') || ({} as IEmployeeCv);
    const { firstName, lastName } = cv.personalInfo;
    // ------------>
    // Array are created at the beginning to count all the usable rows for
    // skills list in orange column
    const languagesList = this.getLanguagesList(cv.languageProficiencies);
    const educationList = this.getEducationList(cv.education);
    const certificationsList = this.getBulletedCertificationList(
      cv.certifications,
    );
    const numLanguagesLines = languagesList?.length || 0;
    const numCertificationsLines = certificationsList?.length || 0;

    this.numSkillsToDisplay =
      NumLinesOrangeColumn -
      numLanguagesLines -
      numCertificationsLines -
      this.numEducationLines;
    // end of calculation
    // ------------>
    const skillsList = this.getBulletedSkillsList(cv.technicalSkills);
    const summarySection = this.getSummarySection(cv.summary.description);
    const workExperienceList = this.getWorkExperienceList(
      cv.workExperience,
      cv.customOffice
    );

    const doc = await BuildDocx({
      logo,
      cv,
      languagesList,
      educationList,
      certificationsList,
      skillsList,
      summarySection,
      workExperienceList,
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `28Stone_CV_${firstName}_${lastName}`);
    });
  }



  async generateCvWithDocxtemplater(): Promise<void> {
    const cv = getDataFromSessionStorage('cv') || ({} as IEmployeeCv);
    const { firstName, lastName } = cv.personalInfo;

    const workExperience = this.buildWorkExperienceDataForTemplate(cv.workExperience, cv.customOffice);
    const summary = cv.summary?.description?.trim()
      ? { rawXmlSummaryDescription: this.parseSummaryToRawXml(cv.summary.description) }
      : null; // Ensure null if summary.description is empty
    const softwareExperience = this.getSoftwareExperienceForTemplate(cv.technicalSkills);
    const languages = this.getLanguagesForTemplate(cv.languageProficiencies);
    const education = this.getEducationForTemplate(cv.education);
    const certifications = this.getCertificationsForTemplate(cv.certifications);

    // Load template from assets folder
    const templateContent = await fetch('/assets/docTemplate/CV_template.docx').then((res) =>
      res.arrayBuffer()
    );

    const zip = new PizZip(templateContent);
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
      parser: angularParser,
    });

    // Data Mapping
    doc.render({
      fullName: `${cv.personalInfo.firstName} ${cv.personalInfo.lastName}`,
      email: cv.contactInfo.workEmail,
      summary: summary,
      workExperience: workExperience,
      softwareExperience: softwareExperience,
      languages: languages,
      education: education,
      certifications: certifications,
    });

    const out = doc.getZip().generate({
      type: 'blob',
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });

    saveAs(out, `28Stone_CV_${firstName}_${lastName}.docx`);
  }


  private getLanguagesForTemplate(languages: ILanguageProficiency[]): string[] {
    const learnedLanguages = this.getLearnedLanguageInOrder(languages);

    const languageStrings = learnedLanguages.map(
      (item) => `${item.language} - ${item.proficiencyDescription}`
    );

    return languageStrings
  }




  // private getEducationForTemplate(education: IEducationItem[]): string[] {
  //   return education.flatMap((item) => {
  //     const { institution, location, degrees } = item;

  //     // Construct institution and location text
  //     const institutionText = institution && location
  //       ? `${CapitalizeFirstLetter(institution)}, ${CapitalizeFirstLetter(location)}`
  //       : institution
  //         ? CapitalizeFirstLetter(institution)
  //         : '';

  //     // Map each degree with its corresponding institution and location
  //     return degrees.map((degree) => {
  //       const degreeText = `${CapitalizeFirstLetter(degree.degreeOrSpecialization)}, ${this.buildPeriod(degree)}`;
  //       return `${degreeText}\n${institutionText}`;
  //     });
  //   });
  // }

  private getEducationForTemplate(
    education: IEducationItem[]
  ): { degree: string; institution: string; location: string }[] {
    return education.flatMap((item) => {
      const { institution, location, degrees } = item;

      // Construct institution text
      const institutionText = institution
        ? CapitalizeFirstLetter(institution)
        : '';

      // Construct location text
      const locationText = location ? CapitalizeFirstLetter(location) : '';

      // Map each degree with its corresponding institution and location
      return degrees.map((degree) => ({
        degree: `${CapitalizeFirstLetter(degree.degreeOrSpecialization)}, ${this.buildPeriod(degree)}`,
        institution: institutionText,
        location: locationText,
      }));
    });
  }



  private getCertificationsForTemplate(certifications: ICvCertificationItem[]): string[] {
    return certifications.map((item) => CapitalizeFirstLetter(item.certificate));
  }


  private buildWorkExperienceDataForTemplate(
    exps: ICvWorkExperienceItem[],
    customOffice: string
  ) {

    const usedListNumbers = new Set<number>(); // Shared state for all calls

    return exps.map((exp) => {
      const { company, location: loc, roles } = exp;
      const location = company.toLowerCase().includes('28stone')
        ? (customOffice || loc || '')
        : (loc || '');

      return {
        companyName: CapitalizeFirstLetter(company),
        location,
        period: this.buildPeriod(exp),
        roles: roles.map((role) => ({
          roleTitle: CapitalizeFirstLetter(role.title),
          rolePeriod: this.buildPeriod(role),
          rawXmlDescription: this.parseHtmlToRawXml(role.description || '', usedListNumbers),
        })),
      };
    });
  }

  private parseHtmlToRawXml(html: string, usedListNumbers: Set<number>): string {
    // Pre-process: Replace <p><br></p> with <p></p>
    html = html.replace(/<p><br><\/p>/g, '<p></p>');

    // Capitalize first letter of text content within <p> and <li> tags
    html = html.replace(
      /(<(?:p|li)[^>]*>)([\s]*)([\w])/g,
      (match, tag, spaces, letter) => `${tag}${spaces}${letter.toUpperCase()}`
    );

    const doc = new DOMParser().parseFromString(html, 'text/html');
  

    // Convert back to string
    html = doc.body.innerHTML;
    let rawXml = '';

    // Track used styles for ordered lists
    const maxListNumbers = 5;

    // Maintain a stack to track active elements
    const elementStack: { tag: string; style?: string; element?: HTMLElement }[] = [];


    const getNextAvailableListNumber = (): number => {
      for (let i = 2; i <= maxListNumbers; i++) {
        if (!usedListNumbers.has(i)) {
          usedListNumbers.add(i);
          return i;
        }
      }
      // Default to ListNumber2 if no available styles
      return 2;
    };


    const traverseNode = (node: Node) => {

      if (node.nodeType === Node.TEXT_NODE) {
        const text = node.textContent?.replace(/\n/g, '') || '';

        if (text.trim()) {
          rawXml += `
                    <w:r>
                        <w:rPr>
                            <w:rFonts w:ascii="Nunito" w:hAnsi="Nunito" w:cs="Nunito"/>
                            <w:sz w:val="18"/> <!-- Font size: 9pt -->
                        </w:rPr>
                        <w:t xml:space="preserve">${escapeXml(text)}</w:t>
                    </w:r>
                `;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;

        switch (element.tagName.toLowerCase()) {
          case 'p':
            // Start a new paragraph with properties
            rawXml += `
                        <w:p>
                            <w:pPr>
                                <w:spacing w:before="0" w:after="0"/>
                                <w:ind w:left="0" w:hanging="0" w:right="1396"/> <!-- Right indent: 0.97in -->
                                <w:jc w:val="both"/> <!-- Justify alignment -->
                            </w:pPr>
                    `;
            traverseChildren(element); // Process child nodes (like bold, italic, etc.)
            rawXml += `</w:p>`;
            break;

          case 'b':
          case 'strong':
            rawXml += `
                        <w:r>
                            <w:rPr>
                                <w:b/>
                                <w:rFonts w:ascii="Nunito" w:hAnsi="Nunito" w:cs="Nunito"/>
                                <w:sz w:val="18"/> <!-- Font size: 9pt -->
                            </w:rPr>
                            <w:t xml:space="preserve">${escapeXml(element.textContent || '')}</w:t>
                        </w:r>
                    `;
            break;

          case 'i':
          case 'em':
            rawXml += `
                        <w:r>
                            <w:rPr>
                                <w:i/>
                                <w:rFonts w:ascii="Nunito" w:hAnsi="Nunito" w:cs="Nunito"/>
                                <w:sz w:val="18"/> <!-- Font size: 9pt -->
                            </w:rPr>
                            <w:t xml:space="preserve">${escapeXml(element.textContent || '')}</w:t>
                        </w:r>
                    `;
            break;

          case 'u':
            rawXml += `
                        <w:r>
                            <w:rPr>
                                <w:u w:val="single"/>
                                <w:rFonts w:ascii="Nunito" w:hAnsi="Nunito" w:cs="Nunito"/>
                                <w:sz w:val="18"/> <!-- Font size: 9pt -->
                            </w:rPr>
                            <w:t xml:space="preserve">${escapeXml(element.textContent || '')}</w:t>
                        </w:r>
                    `;
            break;

          case 'li': {
            const isOrdered = element.parentElement?.tagName.toLowerCase() === 'ol';
            let style = 'ListBullet';
            let numId = 6;

            if (isOrdered) {
              let listNumber: number;
              const parentEntry = elementStack.find(
                (el) => el.element === element.parentElement
              );

              if (parentEntry && parentEntry.style) {
                listNumber = parseInt(parentEntry.style.replace('ListNumber', ''), 10);
              } else {
                listNumber = getNextAvailableListNumber();
                elementStack.push({
                  tag: 'ol',
                  style: `ListNumber${listNumber}`,
                  element: element.parentElement,
                });
              }

              style = `ListNumber${listNumber}`;
              numId = listNumber + 8;
            }

            rawXml += `
                        <w:p>
                            <w:pPr>
                                <w:pStyle w:val="${style}"/>
                                <w:numPr>
                                    <w:ilvl w:val="0"/> <!-- List level -->
                                    <w:numId w:val="${numId}"/> <!-- Reference the correct numId -->
                                </w:numPr>
                                <w:spacing w:before="0" w:after="0"/>
                                <w:ind w:left="0" w:hanging="0" w:right="1396"/> <!-- Right indent: 0.97in -->
                                <w:tabs>
                                    <w:tab w:val="left" w:pos="457"/> <!-- 0.25 inches -->
                                </w:tabs>
                            </w:pPr>
                    `;
            traverseChildren(element);
            rawXml += `</w:p>`;
            break;
          }

          case 'ol':
          case 'ul': {
            const isOrdered = element.tagName.toLowerCase() === 'ol';

            let listNumber: number | undefined;
            let style: string | undefined;

            // Check if the <ol> or <ul> already exists in the stack
            const existingEntry = elementStack.find((el) => el.element === element);

            if (isOrdered) {
              if (existingEntry && existingEntry.style) {
                // Reuse existing style for <ol>
                listNumber = parseInt(existingEntry.style.replace('ListNumber', ''), 10);
                style = existingEntry.style;
              } else {
                // Assign a new style for <ol>
                listNumber = getNextAvailableListNumber();
                style = `ListNumber${listNumber}`;
                elementStack.push({
                  tag: 'ol',
                  style,
                  element,
                });

                usedListNumbers.add(listNumber);

              }
            } else {
              // For <ul>, always use ListBullet style
              style = 'ListBullet';
              elementStack.push({
                tag: 'ul',
                style,
                element,
              });
            }

            // Traverse the children
            traverseChildren(element);

            // No release logic since list numbers are permanently reserved
            break;
          }
          case 'br':
            rawXml += `<w:br/>`;
            break;

          default:
            // For other tags, traverse their children recursively
            traverseChildren(element);
        }
      }
    };

    const traverseChildren = (element: HTMLElement) => {
      Array.from(element.childNodes).forEach((child) => traverseNode(child));
    };

    const escapeXml = (text: string): string => {
      return text
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&apos;');
    };

    Array.from(doc.body.childNodes).forEach((node) => traverseNode(node));

    return rawXml;
  }



  // private parseSummaryToRawXml(html: string): string {
  //   // Pre-process: Replace <p><br></p> with <p></p>
  //   html = html.replace(/<p><br><\/p>/g, '<p></p>');

  //   const doc = new DOMParser().parseFromString(html, 'text/html');
  //   let rawXml = '';

  //   const traverseNode = (node: Node) => {
  //     if (node.nodeType === Node.TEXT_NODE) {
  //       const text = CapitalizeFirstLetter(node.textContent?.replace(/\n/g, '') || '');
  //       if (text.trim()) {
  //         rawXml += `
  //                   <w:r>
  //                       <w:rPr>
  //                           <w:rFonts w:ascii="Nunito" w:hAnsi="Nunito" w:cs="Nunito"/>
  //                           <w:sz w:val="18"/> <!-- Font size: 9pt -->
  //                       </w:rPr>
  //                       <w:t xml:space="preserve">${escapeXml(text)}</w:t>
  //                   </w:r>
  //               `;
  //       }
  //     } else if (node.nodeType === Node.ELEMENT_NODE) {
  //       const element = node as HTMLElement;

  //       switch (element.tagName.toLowerCase()) {
  //         case 'b':
  //         case 'strong':
  //           rawXml += `
  //                       <w:r>
  //                           <w:rPr>
  //                               <w:b/>
  //                               <w:rFonts w:ascii="Nunito" w:hAnsi="Nunito" w:cs="Nunito"/>
  //                               <w:sz w:val="18"/> <!-- Font size: 9pt -->
  //                           </w:rPr>
  //                   `;
  //           traverseChildren(element);
  //           rawXml += `</w:r>`;
  //           break;

  //         case 'i':
  //         case 'em':
  //           rawXml += `
  //                       <w:r>
  //                           <w:rPr>
  //                               <w:i/>
  //                               <w:rFonts w:ascii="Nunito" w:hAnsi="Nunito" w:cs="Nunito"/>
  //                               <w:sz w:val="18"/> <!-- Font size: 9pt -->
  //                           </w:rPr>
  //                   `;
  //           traverseChildren(element);
  //           rawXml += `</w:r>`;
  //           break;

  //         case 'u':
  //           rawXml += `
  //                       <w:r>
  //                           <w:rPr>
  //                               <w:u w:val="single"/>
  //                               <w:rFonts w:ascii="Nunito" w:hAnsi="Nunito" w:cs="Nunito"/>
  //                               <w:sz w:val="18"/> <!-- Font size: 9pt -->
  //                           </w:rPr>
  //                   `;
  //           traverseChildren(element);
  //           rawXml += `</w:r>`;
  //           break;

  //         case 'p':
  //           rawXml += `
  //                       <w:p>
  //                           <w:pPr>
  //                               <w:spacing w:before="0" w:after="0"/>
  //                               <w:ind w:left="0" w:hanging="0" w:right="1396"/> <!-- Right indent: 0.97in -->
  //                               <w:jc w:val="both"/> <!-- Justify text -->
  //                           </w:pPr>
  //                   `;
  //           traverseChildren(element);
  //           rawXml += `</w:p>`;
  //           break;

  //         case 'li':
  //           rawXml += `
  //                       <w:p>
  //                           <w:pPr>
  //                               <w:spacing w:before="0" w:after="0"/>
  //                               <w:ind w:left="0" w:hanging="0" w:right="1396"/> <!-- Right indent: 0.97in -->
  //                               <w:numPr>
  //                                   <w:ilvl w:val="0"/> <!-- List level (0 for top-level items) -->
  //                                   <w:numId w:val="1"/> <!-- Reference to numbering.xml -->
  //                               </w:numPr>
  //                               <w:tabs>
  //                                   <w:tab w:val="left" w:pos="457"/> <!-- 0.25 inches -->
  //                               </w:tabs>
  //                           </w:pPr>
  //                   `;
  //           traverseChildren(element);
  //           rawXml += `</w:p>`;
  //           break;

  //         case 'br':
  //           rawXml += `<w:br/>`;
  //           break;

  //         default:
  //           traverseChildren(element);
  //       }
  //     }
  //   };

  //   const traverseChildren = (element: HTMLElement) => {
  //     Array.from(element.childNodes).forEach((child) => {
  //       if (child.nodeType === Node.TEXT_NODE && child.textContent?.trim() === '') {
  //         return; // Skip empty text nodes
  //       }
  //       traverseNode(child);
  //     });
  //   };

  //   const escapeXml = (text: string): string => {
  //     return text
  //       .replace(/&/g, '&amp;')
  //       .replace(/</g, '&lt;')
  //       .replace(/>/g, '&gt;')
  //       .replace(/"/g, '&quot;')
  //       .replace(/'/g, '&apos;');
  //   };

  //   Array.from(doc.body.childNodes).forEach((node) => traverseNode(node));

  //   return rawXml;
  // }

  private parseSummaryToRawXml(html: string): string {
    // Pre-process: Replace <p><br></p> with <p></p>
    html = html.replace(/<p><br><\/p>/g, '<p></p>');

    const doc = new DOMParser().parseFromString(html, 'text/html');
    let rawXml = '';

    const traverseNode = (node: Node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const text = CapitalizeFirstLetter(node.textContent?.replace(/\n/g, '') || '');
        if (text.trim()) {
          rawXml += `
                    <w:r>
                        <w:rPr>
                            <w:rFonts w:ascii="Nunito" w:hAnsi="Nunito" w:cs="Nunito"/>
                            <w:sz w:val="18"/> <!-- Font size: 9pt -->
                        </w:rPr>
                        <w:t xml:space="preserve">${escapeXml(text)}</w:t>
                    </w:r>
                `;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;

        switch (element.tagName.toLowerCase()) {
          case 'b':
          case 'strong':
            rawXml += `
                        <w:r>
                            <w:rPr>
                                <w:b/>
                                <w:rFonts w:ascii="Nunito" w:hAnsi="Nunito" w:cs="Nunito"/>
                                <w:sz w:val="18"/> <!-- Font size: 9pt -->
                            </w:rPr>
                    `;
            traverseChildren(element);
            rawXml += `</w:r>`;
            break;

          case 'i':
          case 'em':
            rawXml += `
                        <w:r>
                            <w:rPr>
                                <w:i/>
                                <w:rFonts w:ascii="Nunito" w:hAnsi="Nunito" w:cs="Nunito"/>
                                <w:sz w:val="18"/> <!-- Font size: 9pt -->
                            </w:rPr>
                    `;
            traverseChildren(element);
            rawXml += `</w:r>`;
            break;

          case 'u':
            rawXml += `
                        <w:r>
                            <w:rPr>
                                <w:u w:val="single"/>
                                <w:rFonts w:ascii="Nunito" w:hAnsi="Nunito" w:cs="Nunito"/>
                                <w:sz w:val="18"/> <!-- Font size: 9pt -->
                            </w:rPr>
                    `;
            traverseChildren(element);
            rawXml += `</w:r>`;
            break;

          case 'p':
            rawXml += `
                        <w:p>
                            <w:pPr>
                                <w:spacing w:before="0" w:after="0"/>
                                <w:ind w:left="0" w:hanging="0" w:right="1396"/> <!-- Right indent: 0.97in -->
                                <w:jc w:val="both"/> <!-- Justify text -->
                            </w:pPr>
                    `;
            traverseChildren(element);
            rawXml += `</w:p>`;
            break;

          case 'li':
            const isOrdered = element.parentElement?.tagName.toLowerCase() === 'ol';
            const style = isOrdered ? 'ListNumber' : 'ListBullet';

            rawXml += `
                  <w:p>
                      <w:pPr>
                          <w:pStyle w:val="${style}"/>
                          <w:numPr>
                              <w:ilvl w:val="0"/> <!-- List level -->
                              <w:numId w:val="${isOrdered ? '8' : '7'}"/> <!-- Use numId 1 for ordered, 2 for bullet -->
                          </w:numPr>
                          <w:spacing w:before="0" w:after="0"/>
                      </w:pPr>
                      <w:r>
                          <w:rPr>
                              <w:rFonts w:ascii="Nunito" w:hAnsi="Nunito" w:cs="Nunito"/>
                              <w:sz w:val="18"/> <!-- Font size: 9pt -->
                          </w:rPr>
                          <w:t xml:space="preserve">${escapeXml(element.textContent || '')}</w:t>
                      </w:r>
                  </w:p>
              `;
            break;

          case 'br':
            rawXml += `<w:br/>`;
            break;

          default:
            traverseChildren(element);
        }
      }
    };

    const traverseChildren = (element: HTMLElement) => {
      Array.from(element.childNodes).forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE && child.textContent?.trim() === '') {
          return; // Skip empty text nodes
        }
        traverseNode(child);
      });
    };

    const escapeXml = (text: string): string => {
      return text
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&apos;');
    };

    Array.from(doc.body.childNodes).forEach((node) => traverseNode(node));

    return rawXml;
  }



  // Example helper to shape your data
  private buildWorkExperienceData(
    exps: ICvWorkExperienceItem[],
    customOffice: string
  ) {
    return exps.map((exp) => {
      const { company, location: loc, roles } = exp;
      const location = company.toLowerCase().includes('28stone')
        ? customOffice
        : loc;

      return {
        companyName: CapitalizeFirstLetter(company),
        location,
        period: this.buildPeriod(exp), // e.g. "12/2024 - Present"
        roles: roles.map((role) => ({
          roleTitle: CapitalizeFirstLetter(role.title),
          rolePeriod: this.buildPeriod(role),
          // Turn role.description into an array of lines (optional)
          descriptionLines: role.description?.split('\n').filter(Boolean) || [],
        })),
      };
    });
  }

  public parseHtmlToTextRuns(html: string): Paragraph[] {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const paragraphArr: Paragraph[] = [];
    let paragraphObj: DocxParagraphObj = {
      alignment: AlignmentType.JUSTIFIED,
      indent: { left: LeftParagraphPadding, right: RightParagraphPadding },
      children: [],
    };
    let flagBullet: 'bullet' | number = 'bullet';

    const handleNode = (node: Node): TextRun | Paragraph => {
      const tag = node.nodeName.toLowerCase();
      node.textContent = CapitalizeFirstLetter(node.textContent);
      switch (tag) {
        case 'b':
        case 'strong':
          return NewText({ text: node.textContent, bold: true });
        case 'i':
        case 'em':
          return NewText({ text: node.textContent, italics: true });
        case 'u':
        case 'ins':
          return NewText({
            text: node.textContent,
            underline: { type: UnderlineType.SINGLE },
          });
        case 's':
        case 'strike':
        case 'del':
          return NewText({ text: node.textContent, strike: true });
        case 'li':
          return BulletedNumberedParagraph(node.textContent, flagBullet);
        default:
          return NewText({ text: node.textContent });
      }
    };

    // Text from text editor input starts and closes always with <p> or <ul> or <ol>
    for (let i = 0; i < doc.body.childNodes.length; i++) {
      const par = doc.body.childNodes[i];

      for (let j = 0; j < par.childNodes.length; j++) {
        const nodeName = doc.body.childNodes[i].nodeName.toLowerCase();
        nodeName === 'ol' ? (flagBullet = j + 1) : 'bullet';
        const node = par.childNodes[j];

        if (nodeName !== 'ol' && nodeName !== 'ul') {
          paragraphObj.children.push(handleNode(node));
        } else {
          paragraphArr.push(handleNode(node) as Paragraph);
        }
      }
      paragraphArr.push(NewParagraph(paragraphObj));
      paragraphObj.children = [];
    }
    return paragraphArr;
  }

  private getBulletedSkillsList(skills: ITechnicalSkill[]): Paragraph[] {
    const sectionTitle = OrangeColumnSectionTitleParagraph(
      'Software Experience',
      false,
    );
    const filteredSkills = skills.filter((item) => item.willingToUse);

    const sortedSkills = filteredSkills.sort((a, b) =>
      b.skillProficiency.localeCompare(a.skillProficiency) !== 0
        ? b.skillProficiency.localeCompare(a.skillProficiency)
        : b.lastUsed - a.lastUsed !== 0
          ? b.lastUsed - a.lastUsed
          : b.yearsOfExperience - a.yearsOfExperience,
    );

    let bulletStrings = sortedSkills.map((item) =>
      CapitalizeFirstLetter(item.skill),
    );

    if (bulletStrings.length >= this.numSkillsToDisplay) {
      bulletStrings = bulletStrings.slice(0, this.numSkillsToDisplay - 1);
    }

    const paragraphs = bulletStrings.map((bulletString) =>
      BulletedParagraph(bulletString),
    );

    return this.addSectionTitleIfList(paragraphs, sectionTitle);
  }


  private getSoftwareExperienceForTemplate(skills: ITechnicalSkill[]): string[] {
    // Filter skills that the user is willing to use
    const filteredSkills = skills.filter((item) => item.willingToUse);

    // Sort the skills by proficiency, last used, and years of experience
    const sortedSkills = filteredSkills.sort((a, b) =>
      b.skillProficiency.localeCompare(a.skillProficiency) !== 0
        ? b.skillProficiency.localeCompare(a.skillProficiency)
        : b.lastUsed - a.lastUsed !== 0
          ? b.lastUsed - a.lastUsed
          : b.yearsOfExperience - a.yearsOfExperience,
    );

    // Map the sorted skills to an array of their capitalized names
    let bulletStrings = sortedSkills.map((item) =>
      CapitalizeFirstLetter(item.skill),
    );

    // Limit the skills to the number of displayable lines if necessary
    // if (bulletStrings.length >= this.numSkillsToDisplay) {
    //   bulletStrings = bulletStrings.slice(0, this.numSkillsToDisplay - 1);
    // }

    return bulletStrings; // Return as a plain array of strings
  }

  private addSectionTitleIfList(
    paragraphList: Paragraph[],
    titleParagraph: Paragraph,
  ): Paragraph[] {
    if (paragraphList.length === 0 || paragraphList[0] === undefined) {
      return;
    }
    if (!titleParagraph || !Array.isArray(paragraphList)) {
      throw new Error('Invalid arguments');
    }

    const fullSection: Paragraph[] = [titleParagraph, ...paragraphList];
    return fullSection;
  }

  public getLearnedLanguageInOrder(
    languages: ILanguageProficiency[],
  ): IOrderedLearnedLanguage[] {
    const lookupData = this.dataService.lookupData$.getValue() as ILookupData;

    const filteredLanguages = languages
      .filter(
        (item) =>
          item.proficiency && item.proficiency !== 'elementary_proficiency',
      )
      .map((item) => {
        const language =
          lookupData.languages.find((e) => e.key === item.language)
            ?.displayName ?? '';
        const proficiency =
          lookupData.languageProficiencies.find(
            (e) => e.key === item.proficiency,
          )?.displayName ?? '';
        const proficiencyDescription = proficiency.split(' - ')[1] ?? '';
        const level = proficiency.split(' ')[1] ?? 0;
        return {
          language: language,
          proficiencyDescription: proficiencyDescription,
          level: level as number,
        };
      });

    filteredLanguages.sort((a, b) => b.level - a.level);

    if (filteredLanguages.some((l) => l.language === 'English')) {
      filteredLanguages.unshift(
        filteredLanguages.splice(
          filteredLanguages.findIndex((e) => e.language == 'English'),
          1,
        )[0],
      );
    }

    return filteredLanguages;
  }

  private getLanguagesList(languages: ILanguageProficiency[]): Paragraph[] {
    const sectionTitle = OrangeColumnSectionTitleParagraph('Languages');

    const learnedLanguages = this.getLearnedLanguageInOrder(languages);

    const paragraphs = learnedLanguages.map((item) => {
      return LanguageParagraph(item.language, item.proficiencyDescription);
    });
    return this.addSectionTitleIfList(paragraphs, sectionTitle);
  }

  public buildPeriod = ({
    present,
    startDate,
    endTimestamp,
  }: IPeriodItem): string => {
    return startDate
      ? present
        ? `${formatTimestamp(startDate)} - Present`
        : `${formatTimestamp(startDate)} - ${formatTimestamp(endTimestamp)}`
      : '';
  };

  public formatDegree(degree: IEducationDegree): TextRun {
    const degreeText = DegreeText(
      CapitalizeFirstLetter(degree.degreeOrSpecialization),
      this.buildPeriod(degree),
    );
    degreeText?.addChildElement(EmptyTextNewLine);
    return degreeText;
  }

  private getSummarySection(description: string): Paragraph[] {
    return this.addSectionTitleIfList(
      this.parseHtmlToTextRuns(description),
      SummaryTitleParagraph,
    );
  }


  private getWorkExperienceList(
    exps: ICvWorkExperienceItem[],
    customOffice: string,
  ): any[] {
    const sections: any[] = [];

    // Add Work Experience title
    sections.push(WorkExperienceTitleParagraph);

    exps.forEach((item) => {
      const { company, roles, location: loc } = item;
      const location = company.toLocaleLowerCase().includes('28stone')
        ? customOffice
        : loc;

      // Add company content
      sections.push(BlackLineTop);
      sections.push(
        BuildTableCompanyPeriod(
          CompanyNameParagraph(`${CapitalizeFirstLetter(company)}, ${location}`),
          PeriodParagraph(this.buildPeriod(item)),
          true // No need to check for first page
        )
      );
      sections.push(EmptyParagraph);

      // Add roles content
      sections.push(...this.getSectionRolesAndPeriods(roles, true));
    });

    return sections;
  }


  private getSectionRolesAndPeriods(roles, isFirstPage: boolean): DocxRoleSection[] {
    const roleSections: any[] = [];
    for (const role of roles) {
      roleSections.push(
        BuildTableCompanyPeriod(
          RoleParagraph(CapitalizeFirstLetter(role.title)),
          PeriodParagraph(this.buildPeriod(role), true),
          isFirstPage  // Pass the same isFirstPage flag
        ),
      );
      roleSections.push(GreyLineTop);
      roleSections.push(...this.parseHtmlToTextRuns(role.description));
      roleSections.push(EmptyParagraph);
    }
    return roleSections;
  }

  private getEducationList(education: IEducationItem[]): Paragraph[] {
    const sectionTitle = OrangeColumnSectionTitleParagraph('Education');
    const paragraphs = education.map((item: IEducationItem) => {
      const { institution, location } = item;

      const institutionText =
        institution !== '' && location
          ? `${CapitalizeFirstLetter(institution)}, ${CapitalizeFirstLetter(
            location,
          )}`
          : institution !== ''
            ? `${CapitalizeFirstLetter(institution)}`
            : '';

      const degreeText = item.degrees.map((it) => this.formatDegree(it));

      this.numEducationLines =
        degreeText.length > 0 && institutionText !== ''
          ? this.numEducationLines + degreeText.length + 1
          : this.numEducationLines;
      if (institutionText !== '') {
        return EducationParagraph(degreeText, institutionText);
      }
    });
    return this.addSectionTitleIfList(paragraphs, sectionTitle);
  }

  private getBulletedCertificationList(
    certifications: ICvCertificationItem[],
  ): Paragraph[] {
    const sectionTitle = OrangeColumnSectionTitleParagraph('Certifications');
    const bulletStrings = certifications.map((item) =>
      CapitalizeFirstLetter(item.certificate),
    );

    const paragraphs = bulletStrings.map((bulletString) =>
      BulletedParagraph(bulletString),
    );

    return this.addSectionTitleIfList(paragraphs, sectionTitle);
  }
}
