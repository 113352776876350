import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import htmlToPdfmake from 'html-to-pdfmake';
import { combineLatest, throwError } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import {
  ICvCertificationItem,
  ICvWorkExperienceItem,
  IEducationDegree,
  IEducationItem,
  IEmployeeCv,
  ILookupValue,
  IOrderedLearnedLanguage,
  ITechnicalSkill,
  NotificationMode,
} from '@lss/lss-types';
import { buildDate, getBase64, getDataFromSessionStorage } from '@lss/lss-ui';
import { DataService } from '../base/data.service';
import { NotificationService } from '../base/notification/notification.service';
import { DATA_SERVICE_TOKEN } from '@lss/lss-types';
import { LogoImage } from '../mock/cv-image';
import { CapitalizeFirstLetter, trimRichEditorValue } from '../utils';
import { CvFileBuilderService } from '../base/cv-file-builder/cv-file-builder.service';



@Injectable({
  providedIn: 'root',
})
export class CvGenerationService {
  constructor(
    @Inject(DATA_SERVICE_TOKEN)
    private dataService: DataService,
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    private cvFileBuilderService: CvFileBuilderService,
  ) { }

  generatePdf() {
    //@TODO find issue why dataService after refactoring returns null, change and take data from dataService;
    const cv = getDataFromSessionStorage('cv') as IEmployeeCv;

    combineLatest([
      this.httpClient
        .get('assets/fonts/Open_Sans/OpenSans-Segoe-UI-merged.ttf', {
          responseType: 'blob',
        })
        .pipe(switchMap(getBase64)),
      this.httpClient
        .get('assets/fonts/Open_Sans/OpenSans-Segoe-UI-Italic-merged.ttf', {
          responseType: 'blob',
        })
        .pipe(switchMap(getBase64)),
      this.httpClient
        .get('assets/fonts/Open_Sans/OpenSans-Segoe-UI-Bold-merged.ttf', {
          responseType: 'blob',
        })
        .pipe(switchMap(getBase64)),
      this.httpClient
        .get('assets/fonts/Open_Sans/OpenSans-Segoe-UI-BoldItalic-merged.ttf', {
          responseType: 'blob',
        })
        .pipe(switchMap(getBase64)),
    ])
      .pipe(
        first(),
        map(
          ([regular, italics, bold, bolditalics]: [
            string,
            string,
            string,
            string,
          ]) => {
            const fontDefinition = {
              'OpenSans-Segoe-UI-merged.ttf': regular.split(',')[1],
              'OpenSans-Segoe-UI-Italic-merged.ttf': italics.split(',')[1],
              'OpenSans-Segoe-UI-Bold-merged.ttf': bold.split(',')[1],
              'OpenSans-Segoe-UI-BoldItalic-merged.ttf':
                bolditalics.split(',')[1],
            };

            return fontDefinition;
          },
        ),
        tap((fontDefinition) => {
          const dd = documentDefinitionFactory(
            cv,
            this.cvFileBuilderService.getLearnedLanguageInOrder(
              cv.languageProficiencies,
            ),
          );

          pdfMake.vfs = fontDefinition;
          pdfMake.fonts = {
            OpenSans: {
              normal: 'OpenSans-Segoe-UI-merged.ttf',
              bold: 'OpenSans-Segoe-UI-Bold-merged.ttf',
              italics: 'OpenSans-Segoe-UI-Italic-merged.ttf',
              bolditalics: 'OpenSans-Segoe-UI-BoldItalic-merged.ttf',
            },
          };

          const pdf = pdfMake.createPdf(dd);

          // pdf.getBuffer(async (buffer) => {
          //   // Load the PDF into PDF-LIB
          //   const pdfDoc = await PDFDocument.load(buffer);
            
          //   // Get the page count
          //   const pageCount = pdfDoc.getPageCount();
            
          //   // Remove the last page
          //   // empty page was being added to the pdf
          //   // Jira Issue: LSS-2214
          //   pdfDoc.removePage(pageCount - 1);
            
          //   // Save the modified PDF
          //   const modifiedPdfBytes = await pdfDoc.save();
            
          //   // Create and trigger download
          //   const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
          //   const url = window.URL.createObjectURL(blob);
          //   const link = document.createElement('a');
          //   link.href = url;
          //   link.download = `28Stone_CV_${cv.personalInfo?.firstName}_${cv.personalInfo?.lastName}.pdf`;
          //   link.click();
          // });
          

          pdf.download(
            `28Stone_CV_${cv.personalInfo?.firstName}_${cv.personalInfo?.lastName}`,
          );
        }),
        catchError((error) => {
          this.notificationService.open({
            title: 'Cv Generation Error',
            body: error.message,
            mode: NotificationMode.Error,
          });

          return throwError(error);
        }),
      )
      .subscribe();
  }
}

export function documentDefinitionFactory(
  cv: IEmployeeCv,
  learnedLanguages: IOrderedLearnedLanguage[],
) {
  return {
    pageSize: 'LETTER',
    pageMargins: [40, 70, 40, 0],
    header: {
      columns: [
        {
          image: LogoImage,
          width: 106,
          height: 40,
          opacity: 0.8,
          margin: [16, 16, 0, 24],
        },
        {
          canvas: [
            {
              type: 'line',
              x1: -5,
              y1: 41,
              x2: 595 - 2 * 45,
              y2: 41,
              lineWidth: 1.5,
              lineColor: '#f05a28',
            },
          ],
          margin: [40, 5, 0, 15],
        },
      ],
    },
    content: [
      {
        margin: [-25, 0, 0, 0],
        layout: {
          hLineWidth: function () {
            return 1;
          },
          vLineWidth: function () {
            return -0.01;
          },
          hLineColor: function () {
            return 'white';
          },
          vLineColor: function () {
            return 'white';
          },
          paddingLeft: function () {
            return 20;
          },
          paddingRight: function () {
            return 5;
          },
          paddingTop: function () {
            return 15;
          },
          paddingBottom: function () {
            return 15;
          },
        },
        table: {
          widths: [185, 280],
          heights: 700,
          body: [
            [
              {
                columns: [
                  [
                    ...tryGetSidebarSoftwareExperience(cv.technicalSkills),
                    ...tryGetSidebarLanguages(learnedLanguages),
                    tryGetSidebarEducation(cv.education),
                    tryGetSidebarCertifications(cv.certifications),
                  ],
                ],
                style: 'sidebar',
              },
              {
                columns: [
                  [
                    {
                      text: `${cv.personalInfo.firstName} ${cv.personalInfo.lastName}`,
                      style: 'bodyEmployeeName',
                    },
                    {
                      alignment: 'justify',
                      columns: [
                        {
                          link: `mailto:${cv.contactInfo.workEmail}`,
                          decoration: 'underline',
                          text: cv.contactInfo.workEmail,
                          width: 'auto',
                          style: 'link',
                        },
                      ],
                      margin: [0, 0, 0, 0],
                    },
                    {
                      columns: [
                        [
                          ...tryGetBodySummary(cv?.summary?.description),
                          ...tryGetBodyWorkExperience(
                            cv.workExperience,
                            cv.customOffice,
                          ),
                        ],
                      ],
                    },
                  ],
                ],
                style: 'body',
              },
            ],
          ],
        },
      },
    ],
    styles: {
      sidebar: {
        fillColor: '#FDECE5',
        width: 200,
        lineHeight: 1.1,
      },
      sidebarHeader: {
        color: '#f05a28',
        fontSize: 12,
        bold: true,
        margin: [0, 0, 0, 0],
      },
      sidebarList: {
        margin: [0, 0, 0, 8],
        fontSize: 8,
      },
      sidebarListItem: {
        margin: [0, 0, 0, 0],
      },
      body: {
        lineHeight: 1,
      },
      bodyEmployeeName: {
        fontSize: 22,
      },
      bodySectionHeader: {
        fontSize: 12,
        bold: true,
        margin: [0, 16, 0, 10],
      },
      bodySectionSubHeader: {
        fontSize: 14,
        margin: [0, 20, 0, 5],
      },
      link: {
        color: '#3782cd',
        decoration: 'underline',
      },
    },
    defaultStyle: {
      fontSize: 8,
      characterSpacing: 0.2,
      font: 'OpenSans',
    },
  };
}

function sidebarListItem(text: string): {
  text: string;
  style: 'sidebarListItem';
} {
  return { text, style: 'sidebarListItem' };
}

export function tryGetSidebarSoftwareExperience(
  skills: ITechnicalSkill[],
): Array<{}> {
  if (!skills?.length) {
    return [];
  }

  try {
    return [
      {
        text: 'Software Experience',
        style: 'sidebarHeader',
      },
      {
        columns: [
          {
            ul: skills
              .filter((s) => s.willingToUse)
              .map((e) => sidebarListItem(e.skill)),
            style: 'sidebarList',
            width: 170,
            nowrap: false,
          },
        ],
      },
    ];
  } catch (e) {
    return [];
  }
}

export function tryGetSidebarLanguages(
  learnedLanguages: IOrderedLearnedLanguage[],
): Array<{}> {
  if (!learnedLanguages?.length) {
    return [];
  }
  try {
    return [
      {
        text: 'Languages',
        style: 'sidebarHeader',
      },
      ...learnedLanguages.map((e) => ({
        text: `${e.language} - ${e.proficiencyDescription}`,
        margin: [0, 0, 0, 1],
      })),
      {
        text: '',
        style: 'sidebarList',
      },
    ];
  } catch (e) {
    return [];
  }
}

export function getLanguagProficiencyDescription(
  languageProficiency: ILookupValue,
): string {
  const result = languageProficiency?.displayName.split(' - ')[1];

  if (!result) {
    console.warn('Language proficiency description retrieval failed.');
  }

  return result;
}

export function tryGetSidebarEducation(
  educations: IEducationItem[],
): Array<{}> {
  if (!educations?.length) {
    return [];
  }

  try {
    return [
      {
        text: 'Education',
        style: 'sidebarHeader',
      },
      ...educations.map((e) =>
        e.degrees.map((ee) => [
          {
            columns: [
              {
                text: getDegreeAndDateRow(ee),
                margin: [0, 0, 0, 0],
                bold: true,
                width: 170,
                nowrap: false,
              },
            ],
          },
          {
            columns: [
              {
                text: getInsitutionAndLocation(e),
                margin: [0, 0, 0, 0],
                width: 170,
                nowrap: false,
              },
            ],
          },
        ]),
      ),
      {
        text: '',
        style: 'sidebarList',
      },
    ];
  } catch (e) {
    return [];
  }
}

export function tryGetSidebarCertifications(
  certifications: ICvCertificationItem[],
): Array<{}> {
  if (!certifications?.length) {
    return [];
  }

  try {
    return [
      {
        text: 'Certifications',
        style: 'sidebarHeader',
      },
      {
        columns: [
          {
            ul: certifications.map((e) =>
              sidebarListItem(CapitalizeFirstLetter(e.certificate)),
            ),
            style: 'sidebarList',
            width: 170,
            nowrap: false,
          },
        ],
      },
    ];
  } catch (e) {
    return [];
  }
}

export function tryGetBodySummary(description: string): Array<{}> {
  if (!description?.length) {
    return [];
  }

  try {
    return [
      {
        text: 'Summary',
        style: 'bodySectionHeader',
      },
      {
        columns: [
          {
            stack: htmlToPdf(trimRichEditorValue(description)),
            width: 300,
            nowrap: false,
          },
        ],
      },
    ];
  } catch (e) {
    return [];
  }
}

export function tryGetBodyWorkExperience(
  workExperiences: ICvWorkExperienceItem[],
  customOffice: string,
): Array<{}> {
  if (!workExperiences?.length) {
    return [];
  }
  // var roleText = company.Roles.Count() > 1 ? $"{role.StartDate} - {role.EndDate}" : string.Empty;
  try {
    return [
      { text: 'Work Experience', style: 'bodySectionHeader' },
      ...workExperiences.map((e) => [
        {
          columns: [
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: 280,
                  y2: 0,
                  lineWidth: 1,
                },
              ],
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          alignment: 'justify',
          columns: [
            {
              text: getCompanyAndLocation(e, customOffice),
              width: 230,
              fontSize: 9,
              bold: true,
              margin: [0, -2, 0, 8],
              nowrap: false,
            },
            {
              text: `${buildDate(e.startDate, e.present, e.endTimestamp)}`,
              fontSize: 7,
              margin: [0, 1, 0, 0],
              width: 'auto',
            },
          ],
        },
        ...e.roles.map((ee, ii) => [
          {
            columns: [
              {
                text: CapitalizeFirstLetter(ee.title),
                fontSize: 9,
                width: 230,
                nowrap: false,
              },
              {
                text: `${buildDate(ee.startDate, ee.present, ee.endTimestamp)}`,
                fontSize: 7,
                margin: [0, 1, 0, 0],
                width: 'auto',
              },
            ],
            alignment: 'justify',
            fontSize: 9,
            italics: true,
            ...getWorkExperienceTitleMargin(ee?.description),
          },
          {
            columns: [
              {
                stack: htmlToPdf(trimRichEditorValue(ee?.description)),
                width: 300,
                nowrap: false,
              },
            ],
          },
          {
            columns:
              e.roles.length === ii + 1
                ? []
                : [
                  {
                    canvas: [
                      {
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: 210,
                        y2: 0,
                        lineWidth: 1,
                        color: '#d3d3d3',
                      },
                    ],
                  },
                ],
            margin: [0, 0, 0, 10],
          },
        ]),
        { text: '', margin: [0, 0, 0, 15] },
      ]),
    ];
  } catch (e) {
    console.warn(e);
    throw e;
  }
}

function getWorkExperienceTitleMargin(roleDescription): {
  margin?: Array<number>;
} {
  return roleDescription ? {} : { margin: [0, 5, 0, 5] };
}

function htmlToPdf(value: string): any[] {
  // We replace text starting with http, https or www with tag A, assuming user wants to put link in their CV.
  let linkRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
  if (value) value = value.replace(linkRegex, "<a href='$1'>$1</a>");

  let pdf = htmlToPdfmake(value || '', { ignoreStyles: ['background-color'] });
  if (!pdf || typeof pdf === 'string') {
    throw new Error(
      `Generation of PDF block failed from following html: ${value}`,
    );
  }
  pdf = pdf.map((e) => {
    e.margin = [0, 1, 0, 8];
    e.style = [];
    return e;
  });
  return pdf;
}

function getDegreeAndDateRow(degree: IEducationDegree): string {
  const { degreeOrSpecialization, startDate, present, endTimestamp } = degree;
  const datePeriod = buildDate(startDate, present, endTimestamp);
  return joinFragments(
    CapitalizeFirstLetter(degreeOrSpecialization),
    datePeriod,
  );
}

function getCompanyAndLocation(
  workItem: ICvWorkExperienceItem,
  customOffice: string,
): string {
  const { company } = workItem;
  let location = company.toLocaleLowerCase().includes('28stone')
    ? customOffice
    : workItem.location;
  return joinFragments(company, CapitalizeFirstLetter(location));
}

function getInsitutionAndLocation(education: IEducationItem): string {
  const { institution, location } = education;
  return joinFragments(
    CapitalizeFirstLetter(institution),
    CapitalizeFirstLetter(location),
  );
}

function joinFragments(...fragments): string {
  return fragments.filter((frag) => frag).join(', ');
}
